
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=GFS+Didot&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper{

}


.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Demonstrate a "mostly customized" scrollbar
 * (won't be visible otherwise if width/height is specified) */
.mostly-customized-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa;
  /* or add it to the track */
}

/* Add a thumb */
.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
  background: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.shubhga-carousel button {
  height: 50px !important;
  width: 50px !important;
  margin: 10px 20px !important;
  background: black !important;
  display: flex !important;
  top: 42% !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 100% !important;
  font-size: 10px !important;
}
@media only screen and (max-width: 600px) {
  .shubhga-carousel button {
    scale: 0 !important;
  }
}
.shadow-socialIcons {
  --tw-shadow: 0px 14px 24px rgba(0, 0, 0, .303);
  --tw-shadow-colored: 0px 14px 24px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shubhga-carousel button :hover {
  background: black !important;
}

 .control-dots .dot {
  transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity=30);
  box-shadow: 0px 0px 0px !important;
  background: #fff;
  border-radius: 10px !important;
  width: 80px ! important;
  height: 8px ! important;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
.button {
  position: relative;
  background-image: linear-gradient(180deg, #7cb53e, #5c9b26);
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-shadow-textCta {
  text-shadow: 2px 2px #3d124f, 0 -1px #3d124f, -1px 1px #3d124f, 0 3px #3d124f, -2px 2px #3d124f;
}
.drop-shadow-textCta {
  --tw-drop-shadow: drop-shadow(0px 12px 25px rgba(0, 0, 0, .258), 1px 2px 1px #3d124f);
}
.rounded-default {
  border-radius: 20px;
}
.skewed .top {
  transform: skew(-45deg);
  left: -1000px;
  width: calc(50vw + 1000px);
}
.layer {
  position: absolute;
  width: 100vw;
  min-height: 300px;
  overflow: hidden;
  border: 1px solid transparent;
  border-top: 0;
  border-bottom: 0;
}
.bg-strawberry {
  --tw-bg-opacity: 1;
  background-color: rgb(215 85 13/var(--tw-bg-opacity));
}
.bg-sky {
  --tw-bg-opacity: 1;
  background-color: rgb(244 2 1/var(--tw-bg-opacity));
}
.top {
  z-index: 2;
  width: 50vw;
  height: 100%;
}
.bottom {
  z-index: 1;
  height: 100%;
}
.text-blackGrape {
  --tw-text-opacity: 1;
  color: rgb(45 35 80/var(--tw-text-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
}
.text-blackGrape {
  --tw-text-opacity: 1;
  color: rgb(45 35 80/var(--tw-text-opacity));
}
.text-strawberry {
  --tw-text-opacity: 1;
  color: rgb(255 88 161/var(--tw-text-opacity));
}

.bg-blackGrape {
  background-color: rgba(45, 35, 80, .25);
}
.text-darkDeepPurple {
  --tw-text-opacity: 1;
  color: rgb(38 9 52/var(--tw-text-opacity));
}
.text-strawberry {
  --tw-text-opacity: 1;
  color: rgb(255 88 161/var(--tw-text-opacity));
}
::placeholder{
  color:"#514B69"
}

.sky-button {
  position: relative;
  background-image: linear-gradient(180deg, #1ebb47, #218b33);
}

.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper{
  background-color:"#041125" !important;
  width:"100%" !important
  
}





.button-85 {
  padding: 0.6em 2em;
  color: black;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  background-color: white;
  font-weight: bold;
 
 
}

/* .button-85:before {
  content: "";
  background: linear-gradient(45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
} */

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}




/* CSS */
.button-81 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: red;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-81:before {
  content: "";
  background: linear-gradient(45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.button-81:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}


.mowgliImage{
  background: greenyellow;
  box-shadow: 0 0 60px greenyellow;
  border-radius: 100%;
}

.tooltipClasses.tooltip{
  background-color: red;
}
.blur{
  filter: saturate(0%)
}



.scbox {
  --sb-track-color: #a9c4d0;
  --sb-thumb-color: #6BAF8D;
  --sb-size: 6px;
  max-height: 300px;
  overflow: auto;
  padding: 10px;

}

.scbox::-webkit-scrollbar {
  width: var(--sb-size)
}

.scbox::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 3px;
  height: 10px
}

.scbox::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 3px;
  height: 10px

}

@supports not selector(::-webkit-scrollbar) {
  .scbox {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}


.imageop{
  opacity: 0.7;

}


 .stbx01 {
   border: 1px solid #6baf8d;
   padding: 2rem;
   border-radius: 0.6rem;
   position: relative;
   margin: 20px 0;
   min-height: 395px;
 }

 .fade009 {
   filter: saturate(0);
 }

 .fade009:hover {
   filter: saturate(1);
 }

 .ss009 {
   background: #ead218;
   padding: 10px 30px;
   border-radius: 25px;
   color: #01180f;
   font-weight: bold;
   position: absolute;
   top: -25px;
 }


    .allidbox {
      max-height: 200px;
      overflow: auto;
    }
  
    .allidbox ul li {
      line-height: 2.5rem;
      color: #fff;
      font-size: 1.3rem;
      position: relative;
    }
  
    .allidbox ul li span {
      position: absolute;
      right: 0;
    }
  
    .bg-grey {
      background: #435346;
    }
  
    .scb {
      --sb-track-color: #a9c4d0;
      --sb-thumb-color: #6baf8d;
      --sb-size: 6px;
      padding-right: 1rem;
    }
  
    .scb::-webkit-scrollbar {
      width: var(--sb-size);
    }
  
    .scb::-webkit-scrollbar-track {
      background: var(--sb-track-color);
      border-radius: 3px;
    }
  
    .scb::-webkit-scrollbar-thumb {
      background: var(--sb-thumb-color);
      border-radius: 3px;
    }
  
    @supports not selector(::-webkit-scrollbar) {
      .scb {
        scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
      }
    }
